import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-page" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "text-body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t("title.pageNotFound")), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("global.thereIsNoSuchPage")), 1)
    ])
  ]))
}